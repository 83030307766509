<template>
  <v-row justify="center">
    <v-dialog v-model="dialogBool" persistent max-width="880">
      <AlertError2
        class="mb-5"
        v-model="displayError2"
        :resource="displayError2"
      />

      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Cadastro
            </v-stepper-step>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 2" step="2">
              Necessidades Especiais
            </v-stepper-step>
            <v-divider> </v-divider>
            <v-stepper-step :complete="e1 > 3" step="3">
              Endereço
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 px-5 py-5" height="480px">
                <v-form ref="form" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Nome"
                    :rules="nameRules"
                    :counter="60"
                    v-model="name"
                    id="name"
                    placeholder="Nome*"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Nome Social"
                    v-model="socialName"
                    :counter="60"
                    id="social"
                    placeholder="Nome Social"
                    outlined
                    dense
                    class="input-ajust grid-col-segund"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="CPF"
                    masked="false"
                    v-mask.raw="'###.###.###-##'"
                    :rules="cpfRules"
                    v-model="cpfCommission"
                    :disabled="true"
                    id="CPF"
                    placeholder="CPF*"
                    outlined
                    dense
                    class="input-ajust crash"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Data de nascimento"
                    v-model="birthDate"
                    type="date"
                    id="date"
                    placeholder="Data de Nascimento"
                    outlined
                    dense
                    class="input-ajust columns-5-3"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="E-mail*"
                    :counter="70"
                    v-model="email"
                    id="email"
                    type="email"
                    placeholder="E-mail"
                    outlined
                    dense
                    class="input-ajust crash"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Telefone"
                    v-mask="['(##) ####-#####']"
                    v-model="tell"
                    id="date"
                    placeholder="Telefone"
                    outlined
                    dense
                    class="input-ajust columns-5-3"
                    required
                  ></v-text-field>
                  <v-select
                    :items="dataAll.getAllGender"
                    item-text="name"
                    item-value="id"
                    id="sex"
                    v-model="genre"
                    label="Gênero"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="dataAll.getAllBreed"
                    item-text="name"
                    item-value="id"
                    id="breed"
                    v-model="breed"
                    label="Raça/Cor"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-select
                    :items="dataAll.getAllEntity"
                    item-text="name"
                    :rules="mandatory"
                    item-value="id"
                    id="Ôrgão/Entidade"
                    v-model="issuingAgency"
                    label="Ôrgão/Entidade"
                    required
                    dense
                    outlined
                    disabled
                  ></v-select>

                  <v-text-field
                    label="RG"
                    id="tellResi"
                    v-model="rg"
                    v-mask="['##.###.###-##']"
                    placeholder="RG"
                    outlined
                    dense
                    class="input-ajust r"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="Orgão/Emissor"
                    :counter="4"
                    id="tellResi"
                    v-model="issuer"
                    max="4"
                    placeholder="Ex: SSP"
                    outlined
                    dense
                    class="input-ajust r"
                    required
                  ></v-text-field>

                  <v-select
                    :items="sexualitys"
                    item-text="name"
                    item-value="id"
                    v-model="sexuality"
                    label="Sexualidade"
                    dense
                    outlined
                  ></v-select>
                </v-form>
              </v-card>
              <div class="bnt-bottom-conf">
                <v-btn text @click="closeDialog"> Cancelar </v-btn>
                <v-btn color="primary" @click="nexTab()"> Continuar </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card class="mb-12 px-5 py-5" height="300px">
                <v-form ref="form2" lazy-validation class="tab-2-dialog">
                  <v-checkbox
                    v-model="checkedDeficiency"
                    label="Possui Deficiência?"
                  >
                  </v-checkbox>

                  <v-select
                    v-if="checkedDeficiency"
                    :rules="mandatory"
                    :items="dataAll.getAllDeficiency"
                    item-text="name"
                    item-value="id"
                    v-model="selectDeficiency"
                    label="Selecione"
                    outlined
                    multiple
                  ></v-select>

                  <v-checkbox
                    v-model="breastfeeding"
                    label="Estará Amamentando no período da Conferência?"
                  >
                  </v-checkbox>
                </v-form>
              </v-card>
              <v-snackbar v-model="snackbar">
                {{ message2 }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="green"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
              <div class="bnt-bottom-conf">
                <v-btn text @click="closeDialog"> Cancelar </v-btn>
                <v-btn color="primary" @click="e1 = 1"> Voltar </v-btn>
                <v-btn color="primary" @click="nexTabTwo()"> continuar </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card class="mb-12 px-5 py-5" height="300px">
                <v-form ref="form3" lazy-validation class="tab-1-dialog">
                  <v-text-field
                    label="Logradouro"
                    type="text"
                    :counter="60"
                    placeholder="Logradouro"
                    v-model="publicPlace"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="CEP"
                    id="cep"
                    placeholder="CEP"
                    v-model="cep"
                    v-mask="'#####-###'"
                    outlined
                    dense
                    class="input-ajust grid-col-segund"
                    required
                  ></v-text-field>

                  <v-text-field
                    label="BAIRRO"
                    v-model="district"
                    placeholder="BAIRRO"
                    :counter="40"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>

                  <v-select
                    :items="dataAll.getAllCounty"
                    item-text="name"
                    item-value="id"
                    v-model="municipaly"
                    class="input-ajust grid-col-segund"
                    label="Municipio"
                    required
                    dense
                    outlined
                  ></v-select>
                  <v-text-field
                    label="NÚMERO"
                    v-model="numberStreet"
                    type="number"
                    id="numberStreet"
                    :counter="3"
                    min="0"
                    placeholder="Número Residência"
                    outlined
                    dense
                    class="input-ajust grid-col-ajust"
                    required
                  ></v-text-field>
                </v-form>
              </v-card>
              <div class="bnt-bottom-conf">
                <v-btn text @click="closeDialog"> Cancelar </v-btn>
                <v-btn color="primary" @click="e1 = 2"> Voltar </v-btn>
                <v-btn color="primary" @click="submitForm()"> Finalizar </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "@/axios";
import AlertError2 from "../alerts/Error2-component.vue";

export default {
  data() {
    return {
      e1: 1,
      message: [],
      message2: [],
      disabledSubmit: false,

      breastfeeding: false,
      escortActive: false,

      displayError: {
        display: false,
        message: [],
      },

      displayError2: {
        display2: false,
        message2: [],
      },
      dialogImporResponse: false,
      // Route
      genreAllc: [],
      getAllDeficiencyc: [],
      snackbar: false,

      // Cadastro
      name: null,
      socialName: null,
      eixos: [],
      eixo: null,
      modality: 2,
      ownership: null,
      ownerships: [],
      modalitys: ["Presencial"],
      cpfCommission: "",
      segment: null,
      segments: [],
      birthDate: null,
      genre: null,
      breed: null,
      issuingAgency: null,
      rg: null,
      checkedDeficiency: false,
      selectDeficiency: [],
      tell: "",
      issuer: "",
      email: null,
      nameEscort: null,
      rgEscort: null,
      cpfEscort: null,
      nameSocialEscort: null,
      genderEscort: null,
      genreEscort: null,
      sexualitys: [],
      sexuality: "",
      delegateCPF: null,
      id_cpf: null,
      id_rg: null,
      id_email: null,
      id_address: null,
      id_phone: null,
      delegate_id: null,
      requestType: "salvar",
      buttom_name: "CADASTRAR",
      archiveDelegateImport: "",
      disabledCPF: false,
      // Endereço
      publicPlace: null,
      cep: null,
      numberStreet: null,
      municipaly: null,
      district: null,
      archive: {},
      disableSubmitImportArchiveDelegates: false,
      itsAlreadyRegistered: [],
      itsNotRegisterable: [],
      itsRegisterable: [],
      dialogImportResponseMessage: "",
      //Estrutura
      formData: {
        people: {
          name: null,
          sexuality_id: null,
          county_id: null,
          gender_id: null,
          modality_id: null,
          birth_date: null,
          social_name: null,
          agency_id: null,
          breed_id: null,
          conference_id: null, //Id da conferência vinculada
          ownership_id: null,
          has_lactating: null,
          has_deficiency: null,
          companion: [],
          deficiency: [],
        },
        address: {
          cep: null,
          county_id: null,
          public_place: null,
          address_number: null,
          district: null,
        },
        phone: [],
        documents: {
          RG: {
            document_number: null,
            issuing_body: null,
          },
          CPF: {
            document_number: null,
          },
        },
        email: {
          name: null,
        },
      },
      // Regras de validação
      mandatory: [(v) => !!v || "Campo obrigatório!"],
      mandatorySelect: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length >= 1) || "Campo obrigatório!",
      ],

      cpfRules: [
        (v) => !!v || "O CPF é obrigatório",
        (v) => (v && v.length <= 14) || "Você excedeu o tamanho máximo.",
      ],

      nameRules: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 60) || "Você excedeu o tamanho máximo.",
      ],

      numberResidencie: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo!",
        (v) => (v && v > 0) || "O número é abaixo do esperado",
      ],

      nameLogradouro: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      namesTrick: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 40) || "Você excedeu o tamanho máximo!",
      ],

      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "O e-mail não é válido.",
        (v) => (v && v.length <= 70) || "Você excedeu o tamanho máximo.",
      ],
    };
  },

  components: {
    AlertError2,
  },

  props: {
    dataAll: {
      type: Object,
    },
    resource: {
      type: Object,
    },

    dialogBool: {
      type: Boolean,
    },
  },

  created() {
    //console.log(this.resource);
    //console.log(this.dataAll);
  },
  watch: {
    dialogBool(e) {
      if (e) {
        this.editItem(this.resource);
        this.getGender();
        this.getBreed();
        this.getCounty();
        this.getDeficiency();
        this.getEntity();
      }
    },
  },
  methods: {
    getGender() {
      axios.get(`/gender`).then((res) => {
        this.dataAll.getAllGender = res.data;
      });

      axios.get(`sexuality`).then((res) => {
        this.sexualitys = res.data;
      });
    },

    getBreed() {
      axios.get(`/breed`).then((res) => {
        this.dataAll.getAllBreed = res.data;
      });
    },

    getCounty() {
      axios.get(`/county`).then((res) => {
        this.dataAll.getAllCounty = res.data;
      });
    },

    getDeficiency() {
      axios.get(`/deficiency`).then((res) => {
        this.dataAll.getAllDeficiency = res.data;
      });
    },

    getEntity() {
      axios.get(`/agency`).then((res) => {
        this.dataAll.getAllEntity = res.data;
      });
    },
    editItem(item) {
      console.log(item);
      if (item.rg != null && item.rg.length != 0) {
        this.id_rg = item.rg[0].id;
        this.rg = item.rg[0].document_number;
        this.issuer = item.rg[0].issuing_body;
      }

      this.name = item.name;
      if (item.email.length != 0) {
        this.id_email = item.email[0].id;
        this.email = item.email[0].name;
      }

      if (item.phone.length != 0) {
        this.id_phone = item.phone[0].id;
      }

      if (item.address.length != 0) {
        this.id_address = item.address[0].id;
        this.publicPlace = item.address[0].public_place;
        this.cep = item.address[0].cep;
        this.numberStreet = item.address[0].address_number;
        this.municipaly = Number(item.address[0].county_id);
        this.district = item.address[0].district;
      }

      if (item.cpf.length != 0) {
        this.id_cpf = item.cpf[0].id;
        this.cpfCommission = item.cpf[0].document_number;
      }

      if (item.phone.length != 0) {
        this.tell = item.phone[0].phone_number + item.phone[0].ddd;
      }

      //Registro
      this.sexuality = Number(item.sexuality_id);
      this.socialName = item.social_name;

      this.birthDate =
        item.birth_date == null ? "" : item.birth_date.slice(0, 10);
      this.genre = Number(item.gender_id);
      this.breed = Number(item.breed_id);
      this.issuingAgency = Number(item.agency_id);

      this.breastfeeding = Number(item.has_lactating);

      if (item.deficiency.length > 0) {
        this.checkedDeficiency = 1;
      } else {
        this.checkedDeficiency = 0;
      }

      this.selectDeficiency = [];
      for (let i = 0; i < item.deficiency.length; i++) {
        this.selectDeficiency.push(item.deficiency[i].id);
      }

      // Endereço
      this.dialogEdit = true;
    },

    closeDialog() {
      this.$emit("eventname", "close-modal");
    },

    submitForm() {
      if (!this.$refs.form.validate()) return;
      if (!this.$refs.form2.validate()) return;
      if (!this.$refs.form3.validate()) return;
      let phone_id = this.id_phone;
      function removeFirstTwoDigits(phoneNumber) {
        let ddd = phoneNumber.substring(0, 2);
        phoneNumber = phoneNumber.substring(2);
        if (phone_id == null) {
          return [{ ddd: ddd, phone_number: phoneNumber }];
        }

        return [{ id: phone_id, ddd: ddd, phone_number: phoneNumber }];
      }

      //REGEX
      const newCPF = this.cpfCommission
        ? this.cpfCommission.replace(/[^a-zA-Z0-9]/g, "")
        : null;
      const newRG = this.rg ? this.rg.replace(/[^a-zA-Z0-9]/g, "") : null;
      const newPhone = this.tell ? this.tell.replace(/[^\d]/g, "") : null;
      const newCEP = this.cep ? this.cep.replace(/[^a-zA-Z0-9]/g, "") : null;

      // First stap
      this.formData.people.requestType = this.requestType;
      this.formData.people.people_id = this.delegate_id;
      this.formData.documents.CPF.id = this.id_cpf;
      this.formData.documents.RG.id = this.id_rg;
      this.formData.email.id = this.id_email;
      this.formData.address.id = this.id_address;

      this.formData.people.conference_id = this.$route.query.id;
      this.formData.people.social_name = this.socialName;
      this.formData.people.name = this.name;
      this.formData.documents.CPF.document_number = newCPF;
      this.formData.people.birth_date = this.birthDate;
      this.formData.email.name = this.email;
      this.formData.phone = newPhone ? removeFirstTwoDigits(newPhone) : null;

      this.formData.people.sexuality_id = this.sexuality;

      this.formData.people.gender_id = this.genre;
      // this.formData.people.modality_id = this.modality;

      this.formData.documents.RG.issuing_body = this.issuer;
      this.formData.documents.RG.document_number = newRG;
      this.formData.people.agency_id = this.issuingAgency;
      this.formData.people.breed_id = this.breed;

      // End First stap

      // Start Second Stap

      if (this.selectDeficiency.length >= 1) {
        this.formData.people.deficiency = this.selectDeficiency;
      } else {
        this.formData.people.deficiency = null;
      }

      this.formData.people.has_deficiency = this.checkedDeficiency;
      this.formData.people.has_lactating = this.breastfeeding;

      // Start Endereço
      this.formData.address.cep = newCEP;
      this.formData.address.public_place = this.publicPlace;
      this.formData.address.address_number = this.numberStreet;
      this.formData.address.county_id = this.municipaly;
      this.formData.address.district = this.district;
      // End Endereço
      this.disabledSubmit = true;
      axios
        .put(`people/${this.resource.id}`, this.formData)
        .then((res) => {
          this.disabledSubmit = true;
          this.snackbar = true;
          this.message2 = res.data.message;
          this.$refs.form3.reset();
          this.$refs.form2.reset();
          this.$refs.form.reset();
          this.delegateCPF = "";
          this.formData.people = {};
          this.cpfCommission = "";
          this.birthDate = "";
          this.tell = "";
          setTimeout(() => {
            this.disabledSubmit = false;
            this.e1 = 1;
            this.dialog = false;
          }, 4000);
          this.$emit("eventname", "people-update");
        })
        .catch((err) => {
          setTimeout(() => {
            this.displayError2.display2 = false;
            this.disabledSubmit = false;
          }, 5000);

          if (typeof err === "string") {
            this.displayError2.message2 = err;
            this.displayError2.display2 = true;
          }

          if (typeof err !== "string") {
            if (err.data.message) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar delegados",
                text: err.data.message,
              });
            }

            if (err.data.details) {
              this.$swal({
                icon: "error",
                title: "Erro ao cadastrar delegados",
                text: err.data.details,
              });
            }
          }
          this.formData.people = {};
        });
    },

    loadSelects() {
      axios.get(`/county`).then((response) => {
        this.countyListOrgao = response.data;
      });

      axios.get(`/country`).then((response) => {
        this.countryListOrgao = response.data;
      });

      axios.get(`/state`).then((response) => {
        this.stateListOrgao = response.data;
      });
    },

    resetForm() {
      this.requestType = "salvar";
      this.buttom_name = "CADASTRAR";
      this.$refs.form3.reset();
      this.$refs.form2.reset();
      this.$refs.form.reset();
      this.delegateCPF = "";
      this.formData.people = {};
      this.cpfCommission = "";
      this.birthDate = "";
      this.tell = "";
      this.disabledCPF = false;
    },

    validate() {
      this.$refs.form.validate();
    },

    nexTab() {
      if (!this.$refs.form.validate()) return;
      this.e1 = 2;
    },
    nexTabTwo() {
      if (!this.$refs.form2.validate()) return;
      this.e1 = 3;
    },
  },
};
</script>

<style scoped>
.grid-col-ajust {
  grid-column: 1 / span 2 !important;
}

.r {
  grid-row: 5;
}

.grid-col-segund {
  grid-column: 3 / span 2;
}

.bnt-bottom-conf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tab-1-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 194px 210px 175px 185px;
  gap: 8px;
}

.tab-2-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 194px 210px 175px;
  gap: 8px;
}

.tab-3-dialog {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 117px 173px;
  gap: 8px;
}

.crash {
  grid-column: 1 / 3;
}

.columns-5-3 {
  grid-column: 5 / 3;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  background: linear-gradient(to right, #27ae60, #1abc9c);

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}

.card_response {
  overflow-y: scroll;
  overflow-x: hidden !important;
}
</style>