<template>
  <div>
    <v-card class="rounded-lg" elevation="0">
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            class="rounded-lg"
            append-icon="mdi-magnify"
            label="Nome do usuário"
            @keyup.enter="indoDataTable()"
            single-line
            hide-details
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            clearable
            label="CPF"
            placeholder="CPF"
            outlined
            v-mask="'###.###.###-##'"
            v-model="cpf"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-select
            clearable
            dense
            outlined
            :items="county_list"
            v-model="county_id"
            item-text="name"
            item-value="id"
            label="Municipio"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            clearable
            dense
            outlined
            :items="regional_nucleus_health_list"
            v-model="regional_nucleus_health_id"
            item-text="name"
            item-value="id"
            label="Macrorregião"
          ></v-select>
        </v-col>
      </v-row>

      <section class="header-table">
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="success"
          @click="indoDataTable()"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-magnify </v-icon>
          <span class="textBtn"> Pesquisar </span>
        </v-btn>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="#C62828"
          @click="clearSearch"
        >
          <span class="textBtn"> LIMPAR</span>
        </v-btn>
        <div class="space"></div>
        <div class="header-space-table">
          <PeopleCreate :resource="dataAll" v-on:eventname="emmitParent" />
        </div>
        <!-- <v-btn class="rounded-lg" elevation="0" color="primary">
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-plus </v-icon>
          <span class="textBtn" :v-model="openDialogPeopleCreate"> Novo </span>
        </v-btn> -->
      </section>

      <v-data-table
        :headers="headers"
        :items="people_list"
        :footer-props="{
          'items-per-page-text': 'Participantes por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :server-items-length="totalRegister"
        :loading="loading"
        show-expand
        :single-expand="true"
        :expanded.sync="expanded"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <PeopleAction
            :resource="item"
            :dataAll="dataAll"
            v-on:eventname="peopleAction"
          />
        </template>

        <!-- Expand Buttons -->
        <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon
                  @click="
                    expand(true);
                    loadHistoricPeople(item);
                  "
                  v-if="!isExpanded"
                  >mdi-arrow-expand-all</v-icon
                >
                <v-icon color="red" @click="expand(false)" v-if="isExpanded"
                  >mdi-arrow-collapse-all
                </v-icon>
              </span>
            </template>
            <span>Histórico de participações</span>
          </v-tooltip>
        </template>

        <!-- Expanded Data -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-table
              :headers="peopleHistoricalHeader"
              :items="peopleHistorical"
              :footer-props="{
                'items-per-page-text': 'Histórico por Página',
              }"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title
                    >Histórico do Participante {{ item.name }}</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>

              <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ new Date(item.created_at).toLocaleString() }}</span>
              </template>
            </v-data-table>
          </td>
        </template>
        <template v-slot:[`item.cpf[0].document_number`]="{ item }">
          {{ item.cpf[0].document_number | VMask("###.###.###-##") }}
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          <span>{{ new Date(item.updated_at).toLocaleString() }}</span>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="snackbar">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import PeopleAction from "./PeopleACtions-comp.vue";
import PeopleCreate from "./DialogPeopleCreate-comp.vue";

export default {
  data() {
    return {
      dialogBool: false,
      peopleHistorical: [],
      expanded: [],
      singleExpand: false,
      dataAll: {
        getAllGender: [],
        getAllBreed: [],
        getAllCounty: [],
        getAllDeficiency: [],
        getAllEntity: [],
      },
      search: "",
      searchRegister: "",
      cpf: "",
      county_list: [],
      regional_nucleus_health_list: [],
      county_id: "",
      regional_nucleus_health_id: "",
      people_list: [],
      options: {},
      loading: false,
      totalRegister: 0,
      numberOfPages: "",
      page: 1,
      sortBy: "created_at",
      snackbar: null,
      message: "",
      headers: [
        { text: "CPF", value: "cpf[0].document_number", sortable: false },
        {
          text: "Nome",
          align: "start",
          value: "name",
          sortable: false,
        },

        { text: "Múnicipio", value: "address[0].county.name", sortable: false },
        {
          text: "Macrorregião",
          value: "address[0].county.health_region.regional_nucleus_heath.name",
          sortable: false,
        },
        { text: "Última atualização", value: "updated_at", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],

      peopleHistoricalHeader: [
        { text: "Conferência", value: "conference.title", sortable: false },
        { text: "Departamento", value: "departament", sortable: false },
        {
          text: "Nome Social",
          align: "start",
          value: "social_name",
          sortable: false,
        },
        {
          text: "Representação - Delegado(a)",
          value: "segment.name",
          sortable: false,
        },
        {
          text: "Titularidade - Delegado(a)",
          value: "ownership.name",
          sortable: false,
        },
        { text: "Eixo - Delegado(a)", value: "axie", sortable: false },
        { text: "Função - Comissão", value: "roles", sortable: false },
        {
          text: "Orgão ou Entidade de Representação",
          value: "agency.name",
          sortable: false,
        },
        { text: "Instituição", value: "institute", sortable: false },
        { text: "Deficiências", value: "deficiency", sortable: false },
        { text: "Município", value: "county", sortable: false },
        { text: "Macro", value: "regional_nucleus_health", sortable: false },
        { text: "Data de cadastro", value: "created_at", sortable: false },
        { text: "Usuário de Cadastro", value: "user.name", sortable: false },
      ],
    };
  },

  components: {
    PeopleAction,
    PeopleCreate,
    //AlertError,
  },

  watch: {
    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },

    options: {
      handler() {
        this.indoDataTable();
      },
    },
    deep: true,
  },

  methods: {
    openDialogPeopleCreate() {
      this.dialogBool = true;
    },
    emmitParent() {
      this.indoDataTable();
    },
    loadHistoricPeople(item) {
      this.peopleHistorical = [];
      axios
        .get(`/report-people-history`, {
          params: {
            people_id: item.id,
          },
        })
        .then((response) => {
          this.peopleHistorical = response.data;          
        });
    },
    peopleAction(item) {
      if (item == "people-update") {
        console.log("updated");
      }
      this.indoDataTable();
    },
    clearSearch() {
      this.search = "";
      this.county_id = "";
      this.regional_nucleus_health_id = "";
      this.cpf = "";
      this.indoDataTable();
    },
    indoDataTable() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;
      axios
        .get(`/people`, {
          params: {
            page: pageNumber,
            per_page: data.per_page,
            name: this.search,
            county_id: this.county_id,
            regional_nucleus_health_id: this.regional_nucleus_health_id,
            cpf: this.cpf,
          },
        })
        .then((response) => {          
          this.loading = false;
          this.people_list = response.data.data;
          this.totalRegister = response.data.total;
          this.numberOfPages = response.data.last_page;
        });
    },

    getSelects() {
      axios.get("regional-nucleus-health").then((res) => {
        this.regional_nucleus_health_list = res.data;
      });
      axios.get(`/county`).then((response) => {
        this.county_list = response.data;
      });
    },
  },

  mounted() {
    this.getSelects();
  },
};
</script>

<style scoped>
.header-table {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
