<template>
  <div style="display: flex; justify-content: start; align-items: center">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon class="mr-2 iconeEditar" @click="downloadHistoricPeople">
            mdi-file-document
          </v-icon>
        </span>
      </template>
      <span>Baixar Histórico de Participação</span>
    </v-tooltip>

    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon class="mr-2 iconeEditar" @click="openDialogEdit">
            mdi-pencil
          </v-icon>
        </span>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom :color="switchColor">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-switch
            v-model="switchRegister"
            color="success"
            value
            @click="swithRegister()"
          ></v-switch>
        </span>
      </template>
      <span>{{ switchStatus }}</span>
    </v-tooltip>

    <div class="header-table">
      <div class="header-space-table">
        <DialogPeople
          :resource="resource"
          :dataAll="dataAll"
          :dialogBool="dialogBool"
          v-on:eventname="emmitParent"
        />
      </div>
    </div>

    <AlertSuccess v-model="displaySuccess" :resource="displaySuccess" />
  </div>
</template>

<script>
import axios from "@/axios";
import AlertSuccess from "../alerts/Success-component.vue";
import DialogPeople from "./DialogPeople-comp.vue";
//import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
      valid: true,
      switchStatus: "",
      switchColor: "",
      switchRegister: "",
      disabledItensByProfile: "",
      dialogBool: false,
      displayError: {
        display: false,
        message: [],
      },
      displaySuccess: {
        display: false,
        message: [],
      },
    };
  },

  props: {
    dataAll: {
      type: Object,
    },
    resource: {
      type: Object,
    },
  },

  components: {
    DialogPeople,
    AlertSuccess,
    //AlertError,
  },

  watch: {
    profile_id(val) {
      this.switchProfileControl(val.id);
    },
  },

  created() {
    this.verifyStatusRegister();
  },

  methods: {
    downloadHistoricPeople() {
      axios
        .get(
          `report/${this.resource.id}/people-history`,
          { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Histórico do participante ${this.resource.name}`;
          link.click();
          URL.revokeObjectURL(link.href);
          //fileDownload(res.data, 'relatorio.xls');
        });
    },
    emmitParent(value) {
      this.dialogBool = false;
      if (value == "people-update") {
        this.$emit("eventname", "people-update");
      }
      if (value == "close-modal") {
        this.$emit("eventname", "close-modal");
      }
    },
    openDialogEdit() {
      console.log("teste");
      this.dialogBool = true;
    },
    verifyStatusRegister() {
      if (this.resource.deleted_at == null) {
        this.switchRegister = true;
        this.switchStatus = "Desativar";
        this.switchColor = "red";
        return;
      }
      this.switchRegister = false;
      this.switchStatus = "Ativar";
      this.switchColor = "success";
    },
    swithRegister() {
      if (!this.switchRegister) {
        this.disableRegister();
        this.$emit("eventname", "");
        return;
      }

      this.enableRegister();
      this.$emit("eventname", "");
    },
    enableRegister() {
      axios.put(`/people/${this.resource.id}/restore/`).then((response) => {
        this.displaySuccess.message = response.data.message;
        this.displaySuccess.display = true;
      });
    },

    disableRegister() {
      axios.delete(`/people/${this.resource.id}`).then((response) => {
        this.displaySuccess.message = response.data.message;
        this.displaySuccess.display = true;
      });
    },
  },
};
</script>

<style scoped>
.space {
  height: 10px;
}

.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 20px;
}

.banner-edit {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgba(95, 195, 228, 1) 0%,
    rgba(93, 148, 229, 1) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-delete {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(235, 30, 57) 0%,
    rgb(248, 96, 36) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-edit > h4,
.banner-delete > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 15px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  margin-top: 15px;

  color: #f44336;
}

.cardFooter {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}
</style>
